/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect } from 'react';

import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';
import wordcloud from 'highcharts/modules/wordcloud';
import Image from 'next/image';

if (typeof Highcharts === 'object') {
  HighchartsExporting(Highcharts);
  wordcloud(Highcharts);
  // eslint-disable-next-line func-names
  Highcharts.seriesTypes.wordcloud.prototype.deriveFontSize = function (relativeWeight) {
    const maxFontSize = 1 / 7;

    if (relativeWeight >= 0 && relativeWeight < maxFontSize) {
      return 5;
    }
    if (relativeWeight >= maxFontSize && relativeWeight < 2 * maxFontSize) {
      return 10;
    }
    if (relativeWeight >= 2 * maxFontSize && relativeWeight < 3 * maxFontSize) {
      return 15;
    }
    if (relativeWeight >= 3 * maxFontSize && relativeWeight < 4 * maxFontSize) {
      return 20;
    }
    if (relativeWeight >= 4 * maxFontSize && relativeWeight < 5 * maxFontSize) {
      return 25;
    }
    if (relativeWeight >= 5 * maxFontSize && relativeWeight < 6 * maxFontSize) {
      return 30;
    }
    return 30;
  };
}

const WordChart = ({
  highchartsRef, chartOptions, isFullScreen, splitDiv, containerProps, backgroundColor,
  bgImg, bgImgOpacity
}) => {
  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [isFullScreen]);

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [splitDiv]);

  return (
    <>
      <div
        className={'h-full overflow-hidden w-full rounded-lg relative'}
        style={{ backgroundColor } }
      >
        {bgImg && <Image
          style={{ opacity: bgImgOpacity }}
          src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${bgImg}`}
          className='object-cover rounded-lg'
          fill
          alt='background image'
        />}
        <HighchartsReact
          highcharts={Highcharts}
          allowChartUpdate='true'
          ref={highchartsRef}
          options={chartOptions}
          containerProps={containerProps}
        />
      </div>
    </>
  );
};

export default WordChart;
